.session-journal {
    font-family: Arial, Helvetica, sans-serif;
    border: 3px solid #3CB2AD;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 1.7rem 2.4rem;
}

.session-journal__header>.session-journal__row {
    font-weight: bold;
    font-size: 14px;
}

.session-journal__header .transcript-preview {
    text-align: center;
}

.session-journal__row {
    display: grid;
    grid-template-columns: 1fr minmax(1rem, 33%) minmax(400px, 1fr); /* Adjust the width of the third column */
    align-items: center;
    grid-gap: 4rem;
}

.session-journal__body .session-journal__row {
    margin-top: 1rem;
    font-size: 12px;
}

.session-journal__body .journal-topic {
    color: #555770;
}

.session-journal .journal-topic .text {
    margin-bottom: 4px;
}

.session-journal .transcript-preview {
    display: flex;
    align-items: center;
    justify-content: center;
}

.session-journal .transcript-preview .text {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.session-journal .btn-img {
    display: flex;
    background: none;
    border: none;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    transition: all 100ms;
}

.session-journal .btn-img:hover {
    background: #eee;
}

.session-journal .btn-right>svg path {
    stroke: #0DE0BA;
}

.session-journal__row.visible-transcription .btn-right {
    transform: rotate(90deg);
}

.session-journal__row.visible-transcription .transcript-preview .text {
    white-space: unset;
}

.session-journal__body .audio-clip {
    min-height: 32px;
    max-height: 32px;
}

.session-journal audio {
    height: 32px;
    width: 275px;
}
